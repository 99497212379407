import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ProfileForm from './components/ProfileForm';
import Failure from './components/Failure';
import Home from './components/Home';
import ButtonAppBar from './components/NavBar';
import Profile from './components/Profile';
import PublicProfile from './components/PublicProfile';
import './index.scss'
import LoginSuccess from './components/LoginSuccess';

function App() {
  return (
    <div >
     <BrowserRouter>
     <ButtonAppBar/>
        <Switch>
        <Route match path="/login/success">
            <LoginSuccess/>
          </Route>
          <Route exact path="/profile/edit">
            <ProfileForm />
          </Route>
          <Route exact path="/profile/view">
           <Profile/>
          </Route>
          <Route path="/profile/failure">
           <Failure data="Something bad happened, please try again"/>
          </Route>
          <Route exact path="/:user">
            <PublicProfile/>
          </Route>
          <Route path="">
            <Home />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
