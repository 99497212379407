import React from "react"
import { useHistory } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Loading from "./Loading";





export default function LoginSuccess(){

    const history = useHistory();
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    React.useEffect(async () => {
        const accessToken = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUDIENCE,
            scope: "read:current_user",
        });
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'accept': 'application/json', 'Authorization': `Bearer ${accessToken}` }
        };
        isAuthenticated && fetch(`${process.env.REACT_APP_GET_USER_BY_EMAIL_URL}=${user.email}`, requestOptions).then((response) => response.json())
            .then(function (data) {
                if(data){
                    history.push("/profile/view")
                    return
                }
                history.push("/profile/edit")
            })
    }, [isAuthenticated])

    return (
        <div>
           <Loading/>
        </div>
    )

}