
import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import FaceIcon from '@mui/icons-material/Face';
import Box from '@mui/material/Box';
import { useAuth0 } from "@auth0/auth0-react";
import Loading from './Loading';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkIcon from '@mui/icons-material/Link';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';



export default function Profile() {

    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [userData, setUserData] = React.useState({})
    const [loading, setLoading] = React.useState(true)

    function load(val) {
        setLoading(val)
    }

    function setData(data) {
        setUserData(data)
    }

    React.useEffect(async () => {
        load(true)
        const accessToken = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUDIENCE,
            scope: "read:current_user",
        });
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'accept': 'application/json', 'Authorization': `Bearer ${accessToken}` }
        };
        isAuthenticated && fetch(`${process.env.REACT_APP_GET_USER_BY_EMAIL_URL}=${user.email}`, requestOptions).then((response) => response.json())
            .then(function (data) {
                setData(data)
                load(false)
            })
    }, [isAuthenticated])


    function renderSocialLinks() {
        return userData.links.map(function (link) {
            return <Chip icon={<LinkIcon />} label={link.name} variant="outlined" clickable={true} onClick={() => { window.open(link.link, '_blank') }} />
        })

    }

    return (
        <div className="view-profile">
            <Box
                noValidate
                autoComplete="off"
            >
                <Stack direction="row" spacing={1}>
                    {loading ?
                        <Loading /> :
                        <Stack direction="column" spacing={5}>
                            <Chip icon={<FaceIcon />} label={userData?.username} variant="outlined" />
                            {renderSocialLinks()}
                            <Alert severity="info">
                                <AlertTitle>Info</AlertTitle>
                                This is your personalized socialhub link
                                <br />
                                <span>
                                    <strong>
                                        socialhub.sandyjswl.com/{userData?.username}

                                        <Tooltip title="Copy">
                                            <IconButton>
                                                <CopyAllIcon onClick={() => { navigator.clipboard.writeText(`socialhub.sandyjswl.com/${userData?.username}`) }} />
                                            </IconButton>
                                        </Tooltip>


                                    </strong>
                                </span>

                                <br />
                                <br />
                                Share it with you friends

                            </Alert>
                            <Button component={Link} to="/profile/edit" variant="contained">Edit Links</Button>
                        </Stack>
                    }
                </Stack>
            </Box>

        </div>
    );
}