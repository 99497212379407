import React from "react"
import { useHistory } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Loading from "./Loading";
import { v4 as uuidv4 } from 'uuid';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
export default function ProfileForm() {

    const history = useHistory();
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    const q = uuidv4();
    let r = uuidv4();
    let y = uuidv4();

    const initialState = {
        "username": "",
        "links": [
            { id: uuidv4(), "name": "Github", "link": "" },
            { id: uuidv4(), "name": "Linkedin", "link": "" },
            { id: uuidv4(), "name": "Portfolio", "link": "" }
        ]
    }

    const [formData, setFormData] = React.useState(
        initialState
    )

    const [loading, setLoading] = React.useState(true)

    function load(val) {
        setLoading(val)
    }

    function insertFormData(data) {
        setFormData(data)
    }

    React.useEffect(async () => {
        load(true)
        const accessToken = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUDIENCE,
            scope: "read:current_user",
        });
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'accept': 'application/json', 'Authorization': `Bearer ${accessToken}` }
        };
        isAuthenticated && fetch(`${process.env.REACT_APP_GET_USER_BY_EMAIL_URL}=${user.email}`, requestOptions).then((response) => response.json())
            .then(function (data) {
                if (data) {
                    insertFormData(data)
                }
                load(false)
            })
    }, [isAuthenticated])

    function handleChange(event) {
        const { name, value } = event.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        })
    }

    function validate(){
        const socials = formData.links;
        let validation = false;
        socials.forEach(function(element){
            if (element.name && element.link){
            }
            else{
                validation = true;
            }
        })
        return validation;
    }

    function handleKeyChange(event) {
        const { name, value } = event.target
        let links = formData.links
        let val = links.filter(function (l) {
            return l['id'] === name;
        })[0]

        val['name'] = value

        const index = links.findIndex(({ id }) => id === val.id);

        links[index] = val

        setFormData(prev => {
            return {
                ...prev,
                links
            }
        })
    }

    function handleValueChange(event) {
        const { name, value } = event.target
        let links = formData.links
        let val = links.filter(function (l) {
            return l['id'] === name;
        })[0]

        val['link'] = value

        const index = links.findIndex(({ id }) => id === val.id);

        links[index] = val

        setFormData(prev => {
            return {
                ...prev,
                links
            }
        })
    }

    async function saveData(e) {
        e.preventDefault();
        try {

            load(true);
            const accessToken = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUDIENCE,
                scope: "read:current_user",
            });

            formData['email'] = user.email

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'accept': 'application/json', 'Authorization': `Bearer ${accessToken}` },
                body: JSON.stringify(formData)
            };


            fetch(`${process.env.REACT_APP_POST_USER_URL}${formData.username}`, requestOptions)
                .then(response => {
                    if (response.status === 200) {
                        history.push("/profile/view");
                    }
                    else {
                        history.push("/profile/failure");
                    }
                })
                .then(data => {
                });
        } catch (err) {
            history.push("/profile/failure");
        }
    }



    function validateFormData() {
        return formData?.username && !(/\s/g.test(formData.username)) ? false : true;
    }

    function addNewLink(event) {
        const val = { "id": uuidv4(), "name": "", "link": "" }
        const links = formData.links;
        links.push(val)
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                links
            }
        })
    }

    function removeLink(id) {
        
        const oldLinks = formData.links;
        if(oldLinks.length === 1){
            return
        }
        const links = oldLinks.filter(function (obj) {
            return obj.id !== id;
        });

        setFormData(prevFormData => {
            return {
                ...prevFormData,
                links
            }
        })
    }

    function renderInputForms() {
        var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
var regex = new RegExp(expression);
        const socialLinks = formData.links
        return socialLinks.map(function (link) {

            return <div className="link-input">
                <TextField
                    error={link['name'] && !(/\s/g.test(link.name)) ? false : true}
                    id="outlined-basic"
                    label="Website Name"
                    variant="outlined"
                    placeholder="Website Name"
                    onChange={handleKeyChange}
                    name={link['id']}
                    value={link['name']}
                    sx={{ marginRight: "20px", marginBottom: "20px" }}
                />
                <TextField
                    error={link['link'] && link.link.match(regex) ? false : true}
                    id="outlined-basic"
                    label="Link"
                    variant="outlined"
                    placeholder="Link"
                    onChange={handleValueChange}
                    name={link['id']}
                    value={link['link']}
                    helperText="Should start with https"
                    sx={{ width:"300px" }}
                />
                <RemoveCircleIcon fontSize="large" sx={{ paddingLeft: "10px", paddingTop: "10px" }} onClick={() => removeLink(link['id'])} />
            </div>
        })
    }


    return (
        <div>
            {
                loading ? <Loading /> :
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 3},
                        }}
                        className="edit-form"
                        noValidate
                        autoComplete="off"
                    >


                        <TextField
                            error={formData?.username && !(/\s/g.test(formData.username)) ? false : true}
                            id="outlined-basic"
                            label="SocialHub Username"
                            variant="outlined"
                            placeholder="SocialHub Username"
                            onChange={handleChange}
                            name="username"
                            value={formData?.username}
                            className="form-input"
                            helperText="Your SocialHub Username, should not contain spaces"
                        />

                        <div>
                            {renderInputForms()}

                        </div>



                        <Button variant="contained" size="large" onClick={addNewLink}>
                            Add Link <AddCircleOutlineIcon />
                        </Button>

                        <Button variant="contained" onClick={saveData} disabled={validateFormData() || validate()}>Save</Button>

                    </Box>
            }

        </div>
    )

}


