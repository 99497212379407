
import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import FaceIcon from '@mui/icons-material/Face';
import Box from '@mui/material/Box';
import Loading from './Loading';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Failure from './Failure';
import LinkIcon from '@mui/icons-material/Link';


import { useParams } from 'react-router';

export default function PublicProfile() {

    const [userData, setUserData] = React.useState({})
    const [loading, setLoading] = React.useState(true)

    function load(val) {
        setLoading(val)
    }

    function setData(data) {
        setUserData(data)
    }

    const { user } = useParams();

    React.useEffect(() => {
        load(true)
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'accept': 'application/json' }
        };
        fetch(`${process.env.REACT_APP_GET_USER_BY_USERNAME_URL}${user}`, requestOptions).then((response) => response.json())
            .then(function (data) {
                setData(data)
                load(false)
            })
    }, [])



    function renderSocialLinks() {
        return userData.links.map(function (link) {
            return <Chip icon={<LinkIcon />} label={link.name} variant="outlined" clickable={true} onClick={() => { window.open(link.link, '_blank') }} />
        })

    }


    return (
        <div className="view-profile">
            {
                userData ? <Box
                noValidate
                autoComplete="off"
            >
                <Stack direction="row" spacing={1}>
                    {loading ?
                        <Loading /> :

                        <Stack direction="column" spacing={5}>
                            <Chip icon={<FaceIcon />} label={userData?.username} variant="outlined" />
                            {renderSocialLinks()}
                        </Stack>
                    }

                </Stack>
            </Box> 
            :
            <Failure data = "User Not Found"/>
            }

        </div>
    );


}
