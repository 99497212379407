import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import HomeIcon from '@mui/icons-material/Home';
import { useHistory } from 'react-router-dom'

export default function ButtonAppBar() {

  const { user, isAuthenticated, logout } = useAuth0();
  const history = useHistory();


  function handleHomeButtonClick() {
    if (isAuthenticated) {
      history.push("/profile/view")
    }
    history.push("/")
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>

        <Typography component={'div'} variant="h6" component="div" sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }} onClick={() => { history.push("/") }}>
        <Button
                                sx={{ my: 2, color: 'white', display: 'block' }}
                                onClick={() => { history.push("/") }}
                            >
                                SocialHub
                            </Button>
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

                            <Button
                                // key={page}
                                // onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                                onClick={() => { window.open("https://www.sandyjswl.com/", "_blank") }}
                            >
                                About
                            </Button>
                        </Box>


          {isAuthenticated ?
            <span>
              <span>{user.email}</span>
              <Button onClick={() => logout({ returnTo: window.location.origin })} color="inherit">Logout</Button>
            </span> : <Button onClick={() => history.push("/")} color="inherit">Login</Button>
          }
        </Toolbar>
      </AppBar>
    </Box>
  );
}
