
import { useHistory } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import * as React from 'react';
import Button from '@mui/material/Button';
import { isMobile } from 'react-device-detect';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';


export default function Home() {

    const { isAuthenticated, loginWithRedirect } = useAuth0();

    const history = useHistory();

    React.useEffect(() => {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'accept': 'application/json' }
        };
        fetch(`${process.env.REACT_APP_HEALTH_CHECK_URL}`, requestOptions).then((response) => response.json());

        if (isAuthenticated) {
            history.push("/login/success")
        }




    }, [isAuthenticated])

    return (
        <div className='login-page'>
            {
                isMobile ? <div className='mobile-view'> < Alert variant="outlined" severity="info">
                    This website is best viewed from a desktop/laptop browser
                </Alert>  </div> : ""


            }
            <h1>Welcome to SocialHub</h1>
            <Button variant="contained" size="large" onClick={() => loginWithRedirect()} >
                Login / Sign up
            </Button>
            <br></br>
            <h3>Want a demo?</h3>
                <span> Use these credentials</span>
                <Alert icon={false} severity="success" color="info" sx={{whiteSpace: 'pre-line', justifyContent:"left", alignContent:"left", textAlign:"left", marginBottom:"10px"}}>
                    <b>Email address</b> - demouser@sandyjswl.com
                    {"\n"}
                    <b>Password </b>      - demo
                </Alert>
                <Button variant="contained" size="large" onClick={() => loginWithRedirect()} >
                    Demo
                </Button>
        </div >

    )
}