//TODO Handle Failure

export default function Failure(props){

    return (
        <div className="loading">
            <h2>{props.data}</h2>

        </div>
    )
}